import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WorkerInterface } from "../pages/workers/worker.interface";
import { environment } from "src/environment/environment";
import { BehaviorSubject, Observable, map, of } from "rxjs";
import { workerList } from "src/static-data/workers.data";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class WorkerService {
  //? worker states
  private WorkerLoading = new BehaviorSubject<boolean>(false);
  public workerLoading$ = this.WorkerLoading.asObservable();
  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  // % Workers
  setWorker(payload: WorkerInterface | any) {
    return this.http.post(`${environment.apiEndpoint}worker/set`, payload).pipe(
      map((res: any) => {
        if (res.status === 200) {
          return res;
        }
        return;
      })
    );
  }

  getWorker(id: number | string, startWeekTurns: string, endWeekTurns: string) {
    this.WorkerLoading.next(true);
    return this.http
      .post(`${environment.apiEndpoint}worker/${id}`, {
        startWeekTurns,
        endWeekTurns,
      })
      .pipe(
        map((res: any) => {
          if (res.status == 200) {
            this.WorkerLoading.next(false);

            return res.worker;
          }
        })
      );
    // return of(workerList.find((worker: WorkerInterface) => {
    //   return worker.id == id
    // }))
  }

  getWorkers(payload?: any) {
    let page = payload.pageIndex ?   payload.pageIndex + 1: 0
    this.WorkerLoading.next(true);
    return this.http
      .post(`${environment.apiEndpoint}worker/all?page=${page}`, payload)
      .pipe(
        map((res: any) => {
          if (res.status == 200) {
            this.WorkerLoading.next(false);
            return res.workers;
          }
        })
      );
    // return of(workerList)
  }

  //  % Contracts
  deleteWorkerContract(id: number) {
    this.WorkerLoading.next(true);
    return this.http
      .delete(`${environment.apiEndpoint}worker/contract`, { body: { id } })
      .pipe(
        map((res: any) => {
          if (res.status == 200) {
            this.WorkerLoading.next(false);
            return res.data;
          }
          return;
        })
      );
  }
}
