export function dateFormat(data: any, type: any): string | undefined {
  let date = new Date(data);
  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1;
  let dd = date.getDate();
  let hh: any = date.getHours();
  let mn: any = date.getMinutes();
  let sn: any = date.getSeconds();
  if (hh < 10) hh = "0" + String(hh);
  if (mn < 10) mn = "0" + String(mn);
  if (sn < 10) sn = "0" + String(sn);
  if (type === "mdyhms") {
    return `${mm}/${dd}/${yyyy}/${hh}:${mn}:${sn}`;
  } else if (type === "dmyhms") {
    return `${dd}/${mm}/${yyyy}/${hh}:${mn}:${sn}`;
  } else if (type === "dmy") {
    return `${dd}/${mm}/${yyyy}`;
  }
  return;
}
export function moneyFilter(k: any) {
  if (typeof k === "number") {
    return "€ " + k.toLocaleString("de-DE", { minimumFractionDigits: 2 });
  }

  return k;
}
