<div *ngIf="companies$ | async as companies">
  <div class="body-2 p-6 bg-primary-600 text-on-primary-600">
    <p>Oggi</p>
    <div class="display-1">{{ week[dayName - 1] }}</div>
    <div class="display-1">{{ date }}</div>
  </div>

  <mat-divider></mat-divider>

  <mat-nav-list>
    <!-- ? Workers -->
    <h1 matSubheader *ngIf="FilteredWorkers.length > 0">Scadenze Operai</h1>
    <div *ngFor="let expiration of FilteredWorkers">
      <div>
        <!-- ? Operai -->

        <div class="flex flex-row gap-2 items-center px-2">
          <mat-icon class="text-lime-600" svgIcon="mat:person"></mat-icon>
          <a (click)="workerNavigation(expiration.id)" mat-list-item>
            <h6 matListItemTitle>
              {{ expiration.name }}
            </h6>
            <p matListItemLine>
              {{ expiration.label }}
              {{ dateFormat(expiration.date, "dmy") }}
            </p>
          </a>
        </div>
      </div>
    </div>
    <!-- ? Vehicles -->
    <h1 *ngIf="FilteredVehicles.length > 0" matSubheader>Scadenze Mezzi</h1>
    <div *ngFor="let expiration of FilteredVehicles">
      <div>
        <div class="flex flex-row gap-2 items-center px-2">
          <mat-icon
            class="text-yellow-300"
            svgIcon="mat:directions_car"
          ></mat-icon>
          <a (click)="mezziNavigation(expiration.id)" mat-list-item>
            <h6 matListItemTitle>
              {{ expiration.name }}
            </h6>
            <p matListItemLine>
              {{ expiration.label }}
              {{ dateFormat(expiration.date, "dmy") }}
            </p>
          </a>
        </div>
      </div>
    </div>
    <!-- ? contests -->
    <h1 *ngIf="FilteredContests.length > 0" matSubheader>Scadenze Gare</h1>
    <div *ngFor="let expiration of FilteredContests">
      <div>
        <div class="flex flex-row gap-2 items-center px-2">
          <mat-icon
            class="text-teal-600"
            svgIcon="mat:work"
          ></mat-icon>
          <a (click)="mezziNavigation(expiration.id)" mat-list-item>
            <h6 matListItemTitle>
              {{ expiration.name }}
            </h6>
            <p matListItemLine>
              {{ expiration.label }}
              {{ dateFormat(expiration.date, "dmy") }}
            </p>
          </a>
        </div>
      </div>
    </div>

  </mat-nav-list>

</div>
