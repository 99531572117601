import { Component, OnInit } from "@angular/core";
import { VexPopoverRef } from "@vex/components/vex-popover/vex-popover-ref";
import { MatRippleModule } from "@angular/material/core";
import { Router, RouterLink } from "@angular/router";
import { MatIconModule } from "@angular/material/icon";
import { AuthService } from "src/app/services/auth.service";
import { Observable } from "rxjs";
import { AsyncPipe, NgIf } from "@angular/common";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { CompanySelectorComponent } from "../../modals/company-selector/company-selector.component";
import { MatSnackBar, MatSnackBarModule } from "@angular/material/snack-bar";

@Component({
  selector: "vex-sidenav-user-menu",
  templateUrl: "./sidenav-user-menu.component.html",
  styleUrls: ["./sidenav-user-menu.component.scss"],
  imports: [
    AsyncPipe,
    NgIf,
    MatIconModule,
    MatRippleModule,
    MatSnackBarModule,
    RouterLink,
  ],
  standalone: true,
})
export class SidenavUserMenuComponent implements OnInit {
  //% Observables
  user$ = new Observable((observer) => {
    this.authService.getUser().subscribe((user: any) => {
      observer.next(user);
    });
  });
  //% Angular lifecycle
  constructor(
    private authService: AuthService,
    private bottomSheet: MatBottomSheet,
    private router: Router,
    private snackbar: MatSnackBar
  ) {}
  ngOnInit(): void {}

  //% Local functions
  logout(): void {
    sessionStorage.removeItem("access_token");
    this.router.navigate(["login"]);
  }
  changeCompany(user: any) {
    //! Deprecata
    // this.bottomSheet
    //   .open(CompanySelectorComponent, { data: user, disableClose: true })
    //   .afterDismissed()
    //   .subscribe((selectedCompanies: number[]) => {
    //     this.authService
    //       .userSelectCompanies(selectedCompanies)
    //       .subscribe((token: string) => {
    //         if (token) {
    //           let oldToken = sessionStorage.getItem("access_token")
    //           console.log(token == oldToken)
    //           sessionStorage.setItem("access_token", token);
    //           this.snackbar.open("Aziende cambiate con successo", "Chiudi", {
    //             duration: 5000,
    //           });
    //         } else {
    //           this.snackbar.open(
    //             "Qualcosa è andato storto, riprova",
    //             "Chiudi",
    //             {
    //               duration: 5000,
    //             }
    //           );
    //         }
    //       });
    //   });
  }
}
