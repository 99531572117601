import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environment/environment";
import { LoginInterface, RegisterInterface } from "../interfaces/auth.interface";
import { BehaviorSubject, Observable, map, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthService {

  isLoggedIn = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient) {}

  register(payload: RegisterInterface) {
    return this.http.post(`${environment.apiEndpoint}auth/register`, payload).pipe(
      map((res: any) => {
        return res.user;
      })
    );
  }
  
  login(payload: LoginInterface): Observable<string | null> {
    return this.http.post(`${environment.apiEndpoint}auth/login`, payload).pipe(
      map((res: any) => {
        if(res.status == 200) {
          return res
        } return 
      })
    );
  }
  logout(): Observable<string | null> {
    return this.http.post(`${environment.apiEndpoint}auth/logout`, {}).pipe(
      map((res: any) => {
        if(res.status == 200) {
          sessionStorage.removeItem('access_token')
          return res
        } return 
      })
    );
  }
  
  getUser() {
    return this.http.get(`${environment.apiEndpoint}auth/getUser`).pipe(
      map((res:any) => {
        if(res.status == 200) {
          return res.user
        } return false
      })
    );
  }
  
  //! Deprecate
  // userSelectCompanies(payload: number[]){
  //   return this.http.post(`${environment.apiEndpoint}auth/userSelectCompanies`, payload).pipe(
  //     map((res:any) => {
  //       if(res.status == 200) {
  //         return res.token
  //       } return false
  //     })
  //   );
  // }
  // decodeToken(): Observable<number[]> {
  //   return this.http.get(`${environment.apiEndpoint}auth/decodeToken`).pipe(
  //     map((res:any) => {
  //       if(res.status == 200) {
  //         return res.decodedToken.companies
  //       } return false
  //     })
  //   );
  // }
}
