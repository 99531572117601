<div
  *ngIf="companies$ | async as companies"
  [class.container]="isVerticalLayout$ | async"
  class="toolbar text-default w-full px-6 flex items-center"
>
  <button
    (click)="openSidenav()"
    [class.hidden]="isDesktop$ | async"
    mat-icon-button
    type="button"
  >
    <mat-icon svgIcon="mat:menu"></mat-icon>
  </button>
  <button
    *ngIf="colorScheme$ | async as colorScheme"
    (click)="changeTheme(colorScheme)"
    mat-stroked-button
    type="button"
  >
    {{ isDark(colorScheme) ? "Light mode" : "Dark mode" }}
  </button>
  <a
    *ngIf="isVerticalLayout$ | async"
    [routerLink]="['/']"
    class="ltr:mr-4 rtl:ml-4 flex items-center"
  >
    <img alt="Logo" class="w-8 select-none" src="assets/img/logo/logo.svg" />
    <h1
      [class.hidden]="isDesktop$ | async"
      class="text-2xl font-bold tracking-wide ltr:pl-4 rtl:pr-4 m-0 select-none"
    >
      Lunica
    </h1>
  </a>

  <div
    *ngIf="(isVerticalLayout$ | async) && (isNavbarInToolbar$ | async)"
    [class.hidden]="!(isDesktop$ | async)"
    class="px-6 flex-none flex items-center"
  >
    <vex-navigation-item
      *ngFor="let item of navigationItems$ | async"
      [item]="item"
    ></vex-navigation-item>
  </div>

  <span class="flex-1"></span>
  <!-- ? Search icon + search f() -->
  <div class="-mx-1 flex items-center">

    <div class="px-1">
      <vex-toolbar-notifications> </vex-toolbar-notifications>
    </div>
    <div class="px-1">
      <div class="flex justify-between items-center relative">
        <span
        *ngIf="totalCount > 0"
          class="px-1.5 bg-red-600 rounded-full text-sm absolute top-0 right-0 font-semibold text-white"
        >
          {{ totalCount }}
        </span>
      </div>
      <button (click)="openQuickpanel()" mat-icon-button type="button">
        <mat-icon color="primary" svgIcon="mat:bookmarks"></mat-icon>
      </button>
    </div>


    <div *ngIf="userVisible$ | async" class="px-1">
      <vex-toolbar-user></vex-toolbar-user>
    </div>


    <div *ngIf="user$ | async as user" class="flex-none">
      <div
        #userProfileMenuOriginRef
        (click)="openProfileMenu(userProfileMenuOriginRef)"
        class="vex-sidenav-user flex items-center"
        matRipple
        matRippleColor="var(--vex-sidenav-item-ripple-color)"
      >
        <div class="vex-sidenav-user__content flex-auto mx-3">
          <div class="vex-sidenav-user__title">{{ user.name }}</div>
          <div class="vex-sidenav-user__subtitle">
            {{ user.companies[0].name }}
          </div>
        </div>
        <img
          alt="User Avatar"
          class="vex-sidenav-user__image flex-none"
          src="../../../../assets/img/avatars/1.jpg"
        />
      </div>
    </div>
  </div>
</div>

<vex-navigation
  *ngIf="(isVerticalLayout$ | async) && (isNavbarBelowToolbar$ | async)"
  [class.hidden]="!(isDesktop$ | async)"
></vex-navigation>
