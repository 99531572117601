import { Component, OnInit } from '@angular/core';
import { Notification } from '../interfaces/notification.interface';
import { DateTime } from 'luxon';
import { trackById } from '@vex/utils/track-by';
import { VexDateFormatRelativePipe } from '@vex/pipes/vex-date-format-relative/vex-date-format-relative.pipe';
import { RouterLink } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { AsyncPipe, NgClass, NgFor } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { dateFormat } from 'src/app/filters/filters';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'vex-toolbar-notifications-dropdown',
  templateUrl: './toolbar-notifications-dropdown.component.html',
  styleUrls: ['./toolbar-notifications-dropdown.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatRippleModule,
    NgClass,
    NgFor,
    RouterLink,
    VexDateFormatRelativePipe
  ]
})
export class ToolbarNotificationsDropdownComponent implements OnInit {
  notifications: Notification[] = [
    {
      id: '1',
      label: 'Nuovo operaio ì: Nikola Tesla ',
      icon: 'mat:person',
      colorClass: 'text-lime-600',
      datetime: DateTime.local().minus({ hour: 64 })
    },
    {
      id: '2',
      label: 'Nuovo mezzo : FA 258 HB',
      icon: 'mat:directions_car',
      colorClass: 'text-orange-600',
      datetime: DateTime.local().minus({ hour: 250 })
    },
    {
      id: '3',
      label: 'Nuova stazione appaltante: COMUNE DI VENERE III MUNICIPIO ',
      icon: 'mat:shop',
      colorClass: 'text-primary-600',
      datetime: DateTime.local().minus({ hour: 5 })
    },

  ];

  trackById = trackById;
  //% Lifecycle
  constructor(
    private authService: AuthService
  ) {}

  ngOnInit() {}
  // % Local functions

  // % Imported functions
  dateFormat = dateFormat

}
