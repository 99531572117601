import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { NEVER, Observable, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class BearerInterceptor implements HttpInterceptor {

  constructor(private router: Router) {}
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    let token = sessionStorage.getItem('access_token')
    const clonedRequest = req.clone({ setHeaders: { Authorization: `Bearer ${token}`} });

    return next.handle(clonedRequest);
  }
}

