export const environment = {
    production: false,
    apiEndpoint: 'https://lunicaapi.delphinet.it/api/',
    //allowdomain:'18.102.103.246',
    allowdomain:'https://openbonusapi.delphinet.it',
    urlBackend:'http://localhost:4200/',
    googleClientID: '485330421634-qnvsddevdn0v6f6u9l3vc9oavjmbgqrt.apps.googleusercontent.com',
    facebookAppID: '916991743188816',
    googleMapsAPIKey:'AIzaSyDjiI_H5qBxE9DCc0Sa0k4pnV_fuZxPioI',
    userPageSize: 10,
    bonusPageSize: 15,
    recaptchaSiteKey: '6Ld4ex8pAAAAAD0Sx_sjbEQMJl-lv5gMw-QXXIzS'
  };
