import { Component, OnInit } from "@angular/core";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatRippleModule } from "@angular/material/core";
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterModule,
  Routes,
} from "@angular/router";
import { MatListModule } from "@angular/material/list";
import { MatDividerModule } from "@angular/material/divider";
import {  Observable } from "rxjs";
import { AuthService } from "src/app/services/auth.service";
import {
  AsyncPipe,
  KeyValuePipe,
  NgFor,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
} from "@angular/common";
import { WorkerService } from "src/app/services/worker.service";
import { VehicleService } from "src/app/services/mezzi.service";

import { dateInPast } from "src/app/functions/function";

import { dateFormat } from "src/app/filters/filters";
import { MatIconModule } from "@angular/material/icon";
import { CompanyService } from "src/app/services/company.service";

import { VexLayoutService } from "@vex/services/vex-layout.service";
import { VehiclesInterface } from "src/app/pages/mezzi/vehicoles";
import { WorkerInterface } from "src/app/pages/workers/worker.interface";
import moment from "moment";
import { ProcurementInterface } from "src/app/pages/procurements/procurement.interface";

@Component({
  selector: "vex-quickpanel",
  templateUrl: "./quickpanel.component.html",
  styleUrls: ["./quickpanel.component.scss"],
  standalone: true,
  imports: [
    AsyncPipe,
    MatDividerModule,
    MatIconModule,
    MatListModule,
    MatProgressBarModule,
    MatRippleModule,
    NgIf,
    NgFor,
    RouterLink,
    RouterModule,
    KeyValuePipe,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
  ],
})
export class QuickpanelComponent {
  // % Date vars
  date = new Date(Date.now()).toLocaleDateString();
  dayName = new Date(Date.now()).getDay();
  week: string[] = [
    "Lunedì",
    "Martedì",
    "Mercoledì",
    "Giovedì",
    "Venerdì",
    "Sabato",
    "Domenica",
  ];
  daysSpan = 7;

  //  % Observables
  companies$ = new Observable<any>((observer) => {
    this.companyService
      .getExpirationDate(this.daysSpan)
      .subscribe((expirations: any) => {
        this.createObject(expirations);
        observer.next(expirations);
      });
  });

  mezziNavigation(id?: any) {
    this.router.navigateByUrl(`/mezzi/mezzo/${id}`);
    this.layoutService.closeQuickpanel();
  }

  workerNavigation(id?: any) {
    this.router.navigateByUrl(`/workers/worker/${id}`);
    this.layoutService.closeQuickpanel();
  }

  // % Lyfecycle
  constructor(
    private authService: AuthService,
    private workerService: WorkerService,
    private vehicleService: VehicleService,
    private companyService: CompanyService,
    private router: Router,
    private layoutService: VexLayoutService,
    private route: ActivatedRoute
  ) {}
  //% exp Obj
  expirations: any[] = [];
  createObject(expirations: any) {
    // * Worker loop
    expirations.workerList.forEach((worker: WorkerInterface) => {
      let medicalCheck = moment(worker.medicalCheck);
      let insuranceW = moment(worker.insurance);
      // 1* visita medica
      if (medicalCheck.isBetween(moment(), moment().add(this.daysSpan, "d"))) {
        this.expirations.push({
          id: worker.id,
          type: 1,
          name: worker.name,
          label: "Scadenza visita medica",
          date: moment(worker.medicalCheck),
        });
      }
      // 1* assicurazione
      if (insuranceW.isBetween(moment(), moment().add(this.daysSpan, "d"))) {
        this.expirations.push({
          id: worker.id,
          type: 1,
          name: worker.name,
          label: "Scadenza assicurazione",
          date: moment(worker.insurance),
        });
      }
    });

    // * Vehicles loop
    expirations.vehicleList.forEach((vehicle: VehiclesInterface) => {
      let revision = moment(vehicle.revision);
      let insuranceV = moment(vehicle.insurance);
      let stampExp = moment(vehicle.stampExp);
      // 1* revisione
      if (revision.isBetween(moment(), moment().add(this.daysSpan, "d"))) {
        this.expirations.push({
          id: vehicle.id,
          type: 2,
          name: vehicle.plate,
          label: "Scadenza revisione",
          date: moment(vehicle.revision),
        });
      }
      // 1* assicurazione
      if (insuranceV.isBetween(moment(), moment().add(this.daysSpan, "d"))) {
        this.expirations.push({
          id: vehicle.id,
          type: 2,
          name: vehicle.plate,
          label: "Scadenza assicurazione",
          date: moment(vehicle.insurance),
        });
      }
      // 1* assicurazione
      if (stampExp.isBetween(moment(), moment().add(this.daysSpan, "d"))) {
        this.expirations.push({
          id: vehicle.id,
          type: 2,
          name: vehicle.plate,
          label: "Scadenza bollo",
          date: moment(vehicle.stampExp),
        });
      }
    });

    // * Contest loop
    expirations.procurementList.forEach((procurement: ProcurementInterface) => {
      let openingTime = moment(procurement.openingTime);
      let closingTime = moment(procurement.closingTime);
      let workStartDate = moment(procurement.workStartDate);
      let workEndDate = moment(procurement.workEndDate);

      // 1* Data apertura gare (chiusura presentazioni)
      if (openingTime.isBetween(moment(), moment().add(this.daysSpan, "d"))) {
        this.expirations.push({
          id: procurement.id,
          type: 3,
          name: procurement.object,
          label: "Data apertura gara",
          date: moment(procurement.openingTime),
        });
      }
      // 1* data chiusura gare (apertura buste)
      if (closingTime.isBetween(moment(), moment().add(this.daysSpan, "d"))) {
        this.expirations.push({
          id: procurement.id,
          type: 3,
          name: procurement.object,
          label: "Data apertura buste",
          date: moment(procurement.closingTime),
        });
      }
      // 1* data inizio lavori
      if (workStartDate.isBetween(moment(), moment().add(this.daysSpan, "d"))) {
        this.expirations.push({
          id: procurement.id,
          type: 3,
          name: procurement.object,
          label: "Data inizio lavori",
          date: moment(procurement.workStartDate),
        });
      }
      // 1* data fine lavori
      if (workEndDate.isBetween(moment(), moment().add(this.daysSpan, "d"))) {
        this.expirations.push({
          id: procurement.id,
          type: 3,
          name: procurement.object,
          label: "Data fine lavori",
          date: moment(procurement.workEndDate),
        });
      }
    });
  }

  get FilteredWorkers() {
    return this.expirations.filter((exp: any) => {
      return exp.type == 1;
    });
  }

  get FilteredVehicles() {
    return this.expirations.filter((exp: any) => {
      return exp.type == 2;
    });
  }

  get FilteredContests() {
    return this.expirations.filter((exp: any) => {
      return exp.type == 3;
    });
  }
  // % Local functions
  // populateDataArray(companies: any) {
  //   //Todo Crea problemi su procurement -> cashflow
  //   // this.workerService
  //   //   .getWorkers()  // ? per non ottenere i turni di nessuno
  //   //   .subscribe((workers: any) => {
  //   //     this.workerList.next(
  //   //       workers.filter((worker: WorkerInterface) => {
  //   //         return this.dateInPast(worker.medicalCheck, new Date(Date.now()));
  //   //       })
  //   //     );
  //   //   });
  //   //Todo Crea mezzi turni da sistemare (api cambiata)
  //   // this.vehicleService.getVehicoles().subscribe((vehicles: any) => {
  //   //   this.vehicleList.next(
  //   //     vehicles.filter((vehicle: VehiclesInterface) => {
  //   //       return this.dateInPast(vehicle.revision, new Date(Date.now()));
  //   //     })
  //   //   );
  //   // });
  //   // this.procurementService.getGare("", 0, 0, 0).subscribe((gare: any) => {
  //   //   this.jobsList.next(
  //   //     gare.filter((gara: ProcurementInterface) => {
  //   //       return this.dateInPast(gara.closingTime, new Date(Date.now()));
  //   //     })
  //   //   );
  //   // });
  // }

  // % Imported functions
  dateInPast = dateInPast;
  dateFormat = dateFormat;
}
