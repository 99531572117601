import * as moment from "moment";

export function diffDates(d: any, dd: any) {
  const date1: any = new Date(d);
  const date2: any = new Date(dd);
  if (!d || !dd) {
    return 0;
  }
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}

export function getMonday(d: Date) {
  d = new Date(d);
  var day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}
export function dayAdder(date: Date, d: number) {
  return moment(date).add(d, "d").date();
}
export function typer(k: any, decimals: number) {
  if (typeof k === "number") {
    return (
      "€ " + k.toLocaleString("de-DE", { minimumFractionDigits: decimals })
    );
  }
  if (typeof k === "object") {
    return humanDater(k);
  }
  return k;
}
export function humanDater(k: any) {
  let K = new Date(k);
  let yyyy = K.getFullYear();
  let mm: any = K.getMonth() + 1;
  let dd: any = K.getDate();
  if (dd < 10) dd = "0" + String(dd);
  if (mm < 10) mm = "0" + String(mm);
  let formattedDate = dd + "/" + mm + "/" + yyyy;
  return formattedDate;
}
export function jsonDater(k: any) {
  let K = new Date(k);
  let yyyy = K.getFullYear();
  let mm: any = K.getMonth() + 1;
  let dd: any = K.getDate();
  if (dd < 10) dd = "0" + String(dd);
  if (mm < 10) mm = "0" + String(mm);
  let formattedDate = yyyy + "-" + mm + "-" + dd;
  return formattedDate;
}

export function dateTime(k: any) {
  let K = new Date(k);
  let yyyy = K.getFullYear();
  let mm: any = K.getMonth() + 1;
  let dd: any = K.getDate();
  let hh: any = K.getHours();
  let mn: any = K.getMinutes();
  let sn: any = "00";
  if (dd < 10) dd = "0" + String(dd);
  if (mm < 10) mm = "0" + String(mm);
  if (hh < 10) hh = "0" + String(hh);
  if (mn < 10) mn = "0" + String(mn);
  let formattedDate =
    yyyy + "-" + mm + "-" + dd + " " + hh + ":" + mn + ":" + sn;
  return formattedDate;
}
export function rounder(int: number) {
  // if(int > 99999) {
  //   return  (int /10**6).toFixed(1) + 'M'
  // }
  // else if (int > 999) {
  //   return (int /10**3).toFixed(1) + 'K'
  // }
  // else {
  //   return Math.round(int)
  // }
  return int.toFixed(2);
}
export function dateInPast(firstDate: any, secondDate: any) {
  if (
    new Date(firstDate).setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)
  ) {
    return true;
  }
  return false;
}
