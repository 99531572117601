<div class="vex-user-menu" *ngIf="user$ | async as user">
  <!-- <a
  (click)="changeCompany(user)"
    class="vex-user-menu-item"
    matRipple
    matRippleColor="rgb(var(--vex-color-primary-600), 0.1)">
    <mat-icon
      class="vex-user-menu-item__icon icon-sm"
      svgIcon="mat:business_center"></mat-icon>
    <div class="vex-user-menu-item__label">Cambia azienda</div>
  </a>

  <div class="border-b border-divider mx-4"></div> -->
  <a
    (click)="logout()"

    class="vex-user-menu-item"
    matRipple
    matRippleColor="rgb(var(--vex-color-primary-600), 0.1)">
    <mat-icon
      class="vex-user-menu-item__icon icon-sm"
      svgIcon="mat:logout"></mat-icon>
    <div class="vex-user-menu-item__label">Log Out</div>
  </a>
</div>
