import { AuthGuard } from "./Guard/auth.guard";
import { LayoutComponent } from "./layouts/layout/layout.component";
import { VexRoutes } from "@vex/interfaces/vex-route.interface";

export const appRoutes: VexRoutes = [
  //! LOGIN
  {
    path: "login",
    loadComponent: () =>
      import("./pages/pages/auth/login/login.component").then(
        (m) => m.LoginComponent
      ),
  },
  //! REGISTER
  {
    path: "register",
    loadComponent: () =>
      import("./pages/pages/auth/register/register.component").then(
        (m) => m.RegisterComponent
      ),
  },
  //! VEX FORGOT PASSWORD
  {
    path: "forgot-password",
    loadComponent: () =>
      import(
        "./pages/pages/auth/forgot-password/forgot-password.component"
      ).then((m) => m.ForgotPasswordComponent),
  },
  //! VEX COMUNG SOON
  {
    path: "coming-soon",
    loadComponent: () =>
      import("./pages/pages/coming-soon/coming-soon.component").then(
        (m) => m.ComingSoonComponent
      ),
  },
  //! Site page
  {
    path: "",
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        redirectTo: "dashboards",
        pathMatch: "full",
      },
      {
        path: "dashboards",
        loadComponent: () =>
          import(
            "./pages/home-page/dashboard/dashboard-analytics.component"
          ).then((m) => m.DashboardAnalyticsComponent),
      },
      {
        path: "calendar",
        loadComponent: () =>
          import("./pages/home-page/calendar/calendar.component").then(
            (m) => m.CalendarComponent
          ),
      },
      {
        path: "workers",
        loadChildren: () =>
          import("./pages/workers/worker-routing.module").then(
            (m) => m.OperaiRoutingModule
          ),
      },
      {
        path: "mezzi",
        loadChildren: () =>
          import("./pages/mezzi/mezzi-routing.module").then(
            (m) => m.MezziRoutingModule
          ),
      },
      {
        path: "imprese",
        loadChildren: () =>
          import("./pages/committers/ImpreseAppaltanti.-routing.module").then(
            (m) => m.ImpreseRoutingModule
          ),
      },
      {
        path: "gare",
        loadChildren: () =>
          import("./pages/procurements/gare-routing.module").then(
            (m) => m.GareRoutingModule
          ),
      },
      {
        path: "procurements",
        loadChildren: () =>
          import("./pages/procurements/procurements.routing.module").then(
            (m) => m.ProcurementRoutingModule
          ),
      },
      {
        path: "aziende",
        loadChildren: () =>
          import("./pages/outer_companies/outer-companies-routing.module").then(
            (m) => m.OuterCompRoutingModule
          ),
      },
      {
        path: "insurances",
        loadChildren: () =>
          import("./pages/insurances/insurances-routing.module").then(
            (m) => m.InsurancesRoutingModule
          ),
      },
      {
        path: "**",
        loadComponent: () =>
          import("./pages/pages/errors/error-404/error-404.component").then(
            (m) => m.Error404Component
          ),
      },
    ],
  },
];
