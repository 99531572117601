import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, map, of } from "rxjs";
import { environment } from "src/environment/environment";
import { lista_aziende } from "src/static-data/aziende.data";

@Injectable({
  providedIn: "root",
})
export class CompanyService {
  //% Loading
  private companyLoading = new BehaviorSubject<boolean>(false);
  public companyLoading$ = this.companyLoading.asObservable();

  //% Angular lifecycle
  constructor(private http: HttpClient) {}
  //% Api
  //! Deprecata


  setCompany(body: any) {
    this.companyLoading.next(true);
    return this.http.post(`${environment.apiEndpoint}company/set`, body).pipe(
      map((res: any) => {
        if (res.status == 200) {
          this.companyLoading.next(false);
          return res.company;
        }
      })
    );
  }

  getExpirationDate(daysSpan?: any, month?: any) {
    this.companyLoading.next(true);
    return this.http
      .post(`${environment.apiEndpoint}company/expiry`, { daysSpan, month })
      .pipe(
        map((res: any) => {
          if (res.status == 200) {
            this.companyLoading.next(false);
            return res;
          }
        })
      );
  }

  allCompanies(payload?: any) {
    let page = payload?.pageIndex ? `?page=${payload.pageIndex+1}`:''
    this.companyLoading.next(true);
    return this.http
      .post(`${environment.apiEndpoint}company/all${page}`, { ...payload })
      .pipe(
        map((res: any) => {
          if (res.status == 200) {
            this.companyLoading.next(false);
            return res.companies;
          }
        })
      );
  }
  getCompany(id: any) {
    this.companyLoading.next(true);
    return this.http.get(`${environment.apiEndpoint}company/${id}`).pipe(
      map((res: any) => {
        if (res.status == 200) {
          this.companyLoading.next(false);
          return res.company;
        }
      })
    );
  }
}
