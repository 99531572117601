import {
  Component,
  DestroyRef,
  ElementRef,
  HostBinding,
  inject,
  Input,
  OnInit,
} from "@angular/core";
import { VexLayoutService } from "@vex/services/vex-layout.service";
import { VexConfigService } from "@vex/config/vex-config.service";
import { filter, map, startWith, switchMap } from "rxjs/operators";
import { NavigationService } from "../../../core/navigation/navigation.service";
import { VexPopoverService } from "@vex/components/vex-popover/vex-popover.service";
import { MegaMenuComponent } from "./mega-menu/mega-menu.component";
import { noop, Observable, of } from "rxjs";
import { NavigationComponent } from "../navigation/navigation.component";
import { ToolbarUserComponent } from "./toolbar-user/toolbar-user.component";
import { ToolbarNotificationsComponent } from "./toolbar-notifications/toolbar-notifications.component";
import { NavigationItemComponent } from "../navigation/navigation-item/navigation-item.component";
import { MatMenuModule } from "@angular/material/menu";
import { NavigationEnd, Router, RouterLink } from "@angular/router";
import {
  AsyncPipe,
  NgClass,
  NgFor,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
} from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { NavigationItem } from "../../../core/navigation/navigation-item.interface";
import { checkRouterChildsData } from "@vex/utils/check-router-childs-data";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AuthService } from "src/app/services/auth.service";
import { SidenavUserMenuComponent } from "../sidenav/sidenav-user-menu/sidenav-user-menu.component";
import { VexColorScheme, VexConfig } from "@vex/config/vex-config.interface";
import { CompanyService } from "src/app/services/company.service";
import { id } from "date-fns/locale";
import { WorkerInterface } from "src/app/pages/workers/worker.interface";
import { VehiclesInterface } from "src/app/pages/mezzi/vehicoles";
import moment from "moment";
import { ProcurementInterface } from "src/app/pages/procurements/procurement.interface";

@Component({
  selector: "vex-toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"],
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    NgIf,
    RouterLink,
    MatMenuModule,
    NgClass,
    NgFor,
    NavigationItemComponent,
    ToolbarNotificationsComponent,
    ToolbarUserComponent,
    NavigationComponent,
    AsyncPipe,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
  ],
})
export class ToolbarComponent implements OnInit {
  @HostBinding("class.shadow-b")
  showShadow: boolean = false;
  //% Style data streams
  navigationItems$: Observable<NavigationItem[]> =
    this.navigationService.items$;
  configs: VexConfig[] = this.configService.configs;
  config$: Observable<VexConfig> = this.configService.config$;

  isHorizontalLayout$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.layout === "horizontal")
  );
  isVerticalLayout$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.layout === "vertical")
  );
  isNavbarInToolbar$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.navbar.position === "in-toolbar")
  );
  isNavbarBelowToolbar$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.navbar.position === "below-toolbar")
  );
  userVisible$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.toolbar.user.visible)
  );
  title$: Observable<string> = this.configService.select(
    (config) => config.sidenav.title
  );

  isDesktop$: Observable<boolean> = this.layoutService.isDesktop$;
  megaMenuOpen$: Observable<boolean> = of(false);

  userMenuOpen$: Observable<boolean> = of(false);
  colorScheme$: Observable<VexColorScheme> = this.config$.pipe(
    map((config) => config.style.colorScheme)
  );
  //% Data streams
  user$ = new Observable<any>((observer) => {
    this.authService.getUser().subscribe((user: any) => {
      observer.next(user);
    });
  });
  daysSpan = 7;
  companies$ = new Observable<any>((observer) => {
    this.companyService
      .getExpirationDate(this.daysSpan)
      .subscribe((res: any) => {
        this.buildChip(res);
        observer.next(res);
      });
  });
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  constructor(
    private authService: AuthService,
    private readonly layoutService: VexLayoutService,
    private readonly configService: VexConfigService,
    private readonly navigationService: NavigationService,
    private readonly popoverService: VexPopoverService,
    private readonly router: Router,
    private companyService: CompanyService
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(null),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.showShadow = checkRouterChildsData(
          this.router.routerState.root.snapshot,
          (data) => data.toolbarShadowEnabled ?? false
        );
      });
  }

  expiration: any[] = [];

  totalCount = 0;
  expirations: any[] = [];

  buildChip(expirations: any) {
    // * Worker loop
    expirations.workerList.forEach((worker: WorkerInterface) => {
      let medicalCheck = moment(worker.medicalCheck);
      let insuranceW = moment(worker.insurance);
      // 1* visita medica
      if (medicalCheck.isBetween(moment(), moment().add(this.daysSpan, "d"))) {
        this.totalCount += 1;
      }
      // 1* assicurazione
      if (insuranceW.isBetween(moment(), moment().add(this.daysSpan, "d"))) {
        this.totalCount += 1;
      }
    });

    // * Vehicles loop
    expirations.vehicleList.forEach((vehicle: VehiclesInterface) => {
      let revision = moment(vehicle.revision);
      let insuranceV = moment(vehicle.insurance);
      let stampExp = moment(vehicle.stampExp);
      // 1* revisione
      if (revision.isBetween(moment(), moment().add(this.daysSpan, "d"))) {
        this.totalCount += 1;
      }
      // 1* assicurazione
      if (insuranceV.isBetween(moment(), moment().add(this.daysSpan, "d"))) {
        this.totalCount += 1;
      }
      // 1* assicurazione
      if (stampExp.isBetween(moment(), moment().add(this.daysSpan, "d"))) {
        this.totalCount += 1;
      }
    });
    // * Contest loop
    expirations.procurementList.forEach((procurement: ProcurementInterface) => {
      let openingTime = moment(procurement.openingTime);
      let closingTime = moment(procurement.closingTime);
      let workStartDate = moment(procurement.workStartDate);
      let workEndDate = moment(procurement.workEndDate);

      // 1* Data apertura gare (chiusura presentazioni)
      if (openingTime.isBetween(moment(), moment().add(this.daysSpan, "d"))) {
        this.totalCount += 1;
      }
      // 1* data chiusura gare (apertura buste)
      if (closingTime.isBetween(moment(), moment().add(this.daysSpan, "d"))) {
        this.totalCount += 1;
      }
      // 1* data inizio lavori
      if (workStartDate.isBetween(moment(), moment().add(this.daysSpan, "d"))) {
        this.totalCount += 1;
      }
      // 1* data fine lavori
      if (workEndDate.isBetween(moment(), moment().add(this.daysSpan, "d"))) {
        this.totalCount += 1;
      }
    });
  }
  openQuickpanel(): void {
    this.layoutService.openQuickpanel();
  }

  openSidenav(): void {
    this.layoutService.openSidenav();
  }

  openMegaMenu(origin: ElementRef | HTMLElement): void {
    this.megaMenuOpen$ = of(
      this.popoverService.open({
        content: MegaMenuComponent,
        origin,
        offsetY: 12,
        position: [
          {
            originX: "start",
            originY: "bottom",
            overlayX: "start",
            overlayY: "top",
          },
          {
            originX: "end",
            originY: "bottom",
            overlayX: "end",
            overlayY: "top",
          },
        ],
      })
    ).pipe(
      switchMap((popoverRef) => popoverRef.afterClosed$.pipe(map(() => false))),
      startWith(true)
    );
  }

  openSearch(): void {
    this.layoutService.openSearch();
  }
  openProfileMenu(origin: HTMLDivElement): void {
    this.userMenuOpen$ = of(
      this.popoverService.open({
        content: SidenavUserMenuComponent,
        origin,
        offsetY: 50,
        width: origin.clientWidth,
        position: [
          {
            originX: "center",
            originY: "bottom",
            overlayX: "center",
            overlayY: "bottom",
          },
        ],
      })
    ).pipe(
      switchMap((popoverRef) => popoverRef.afterClosed$.pipe(map(() => false))),
      startWith(true)
    );
  }
  changeTheme(config: VexColorScheme) {
    !this.isDark(config) ? this.enableDarkMode() : this.disableDarkMode();
  }
  isDark(colorScheme: VexColorScheme): boolean {
    return colorScheme === VexColorScheme.DARK;
  }
  enableDarkMode(): void {
    this.configService.updateConfig({
      style: {
        colorScheme: VexColorScheme.DARK,
      },
    });
    localStorage.setItem("lunicaDarkMode", "1");
  }

  disableDarkMode(): void {
    this.configService.updateConfig({
      style: {
        colorScheme: VexColorScheme.LIGHT,
      },
    });
    localStorage.removeItem("lunicaDarkMode");
  }
}
