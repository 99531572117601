import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { VehiclesInterface } from "../pages/mezzi/vehicoles";
import { BehaviorSubject, Observable, map, of } from "rxjs";
import { vehicolesList } from "src/static-data/mezzi.data";
import { dateInPast } from "../functions/function";
import { env } from "process";
import { environment } from "src/environment/environment";
@Injectable({
  providedIn: "root",
})
export class VehicleService {
  VehicleLoading = new BehaviorSubject<boolean>(false);
  vehicoleLoading$ = this.VehicleLoading.asObservable();
  constructor(private http: HttpClient) {}

  setVehicle(payload: any) {
    // ? Modifica mezzo
    this.VehicleLoading.next(true);
    return this.http.post(`${environment.apiEndpoint}vehicles/set`,{...payload}).pipe(
      map((res: any) => {
        if (res.status == 200) {
          this.VehicleLoading.next(false);
          return res.vehicle;
        }
      })
    );
  }

  getVehicle(id: number | string, startWeekTurns: string, endWeekTurns: string) {
    this.VehicleLoading.next(true);
    return this.http.post(`${environment.apiEndpoint}vehicles/${id}`, {startWeekTurns, endWeekTurns}).pipe(
      map((res: any) => {
        if (res.status == 200) {
          this.VehicleLoading.next(false);
          return res.response;
        }
      })
    );
  }

  getLicense(id: number | string) {
    this.VehicleLoading.next(true);
    return this.http.get(`${environment.apiEndpoint}vehicles/insurances/${id}`).pipe(
      map((res: any) => {
        if (res.status == 200) {
          this.VehicleLoading.next(false);
          return res.response;
        }
      })
    );
  }

  getVehicles(payload?: any) {
    let page = payload.pageIndex  ? payload.pageIndex + 1 : 0
    this.VehicleLoading.next(true);
    return this.http
      .post(`${environment.apiEndpoint}vehicles/all?page=${page}`, { ...payload })
      .pipe(
        map((res: any) => {
          if (res.status == 200) {
            this.VehicleLoading.next(false);
            return res.response;
          }
          return [];
        })
      );
    //? Data scaduta
  }
}
