import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { VexColorScheme } from '@vex/config/vex-config.interface';
import { VexConfigService } from '@vex/config/vex-config.service';

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet]
})
export class AppComponent {

  //% Angular lifecicle
  constructor(private configService: VexConfigService){}
  
  ngOnInit() {
    if(localStorage.getItem("lunicaDarkMode")) {
      this.configService.updateConfig({
        style: {
          colorScheme: VexColorScheme.DARK
        }
      });
    }
  }
}
